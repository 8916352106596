import _isUtf from "is-utf8";
import _buffer from "buffer";
var exports = {};
var Buffer = _buffer.Buffer;
var isUtf8 = _isUtf;

exports = function (x) {
  // Catches EFBBBF (UTF-8 BOM) because the buffer-to-string
  // conversion translates it to FEFF (UTF-16 BOM)
  if (typeof x === "string" && x.charCodeAt(0) === 65279) {
    return x.slice(1);
  }

  if (Buffer.isBuffer(x) && isUtf8(x) && x[0] === 239 && x[1] === 187 && x[2] === 191) {
    return x.slice(3);
  }

  return x;
};

export default exports;